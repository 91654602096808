@media (min-width: 576px) {
    .modal-dialog {
        --bs-modal-width: 400px !important;
    }
}

.modal-dialog {
    .modal-title {
        font-size: 1.8rem;
        color: var(--primary-color);
        font-weight: 600;
    }

    .title-wrapper {
        flex: 1;
        text-align: center;
    }

    input.bs-input {
        font-size: 1.4rem;
        caret-color: var(--primary-color);
    }

    input.bs-input:focus {
        border-color: #ccc;
        box-shadow: none;
    }

    .modal-footer button {
        font-size: 1.4rem;
        padding: 0.5rem 1.5rem;
    }

    .modal-footer .btn-login {
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        &:hover {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            opacity: 0.8;
        }
    }

    .custom-label {
        font-size: 1.3rem;
        color: var(--primary-color);
        font-weight: 600;
    }
}

.btn-login:disabled {
    background-color: #f5f5f5;
    color: #ccc;
    pointer-events: unset !important;
    cursor: not-allowed;
}

.password-wrapper {
    position: relative;

    .show-password {
        background-color: transparent;
        position: absolute;
        cursor: pointer;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        img {
            width: 20px;
            height: 20px;
        }
    }
}
