input[type="file"] {
    display: none;
}

.wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    height: calc(94vh - 50px);
}

.card {
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-bottom: 20px;
    width: 60%;
    height: 90%;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    color: #777070;

    .title {
        color: #777070;
    }

    .input-container {
        flex-shrink: 0;
        padding-left: 20px;
        border: 1px solid #ccc;
        margin: 10px 0;
        border-radius: 10px;
        overflow: hidden;
    }

    .post-title {
        padding: 10px 0;
        font-size: 1.5rem;
        caret-color: var(--primary-color);
        width: 100%;
    }

    .upload-area {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #ccc;
        border-radius: 8px;
        flex: 1;
    }

    .underline {
        text-decoration: underline;
        cursor: pointer;
        background-color: transparent;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
    }
}

.upload-area {
    .content {
        text-align: center;
    }

    .upload-icon {
        width: 120px;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
    }

    .preview-image {
        width: 400px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }

    .preview-video {
        width: 500px;
        object-fit: cover;
    }
}

.preview-wrapper {
    position: relative;
    max-height: 350px;
    overflow: hidden;

    .btn-delete-preview {
        display: flex;
        border-radius: 50%;
        width: 20px;
        background: #fff;
        height: 20px;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;

        &:hover {
            color: red;
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    text-align: right;

    button {
        display: inline-block;
        padding: 8px 16px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid #ccc;
        font-weight: 600;
        color: #777070;

        &:hover {
            color: var(--primary-color);
        }

        &:disabled {
            cursor: not-allowed;
            color: #ccc;
            background-color: #f5f5f5;
        }
    }

    button + button {
        margin-left: 10px;
    }

    .btn-submit {
        position: relative;
    }

    .btn-submit:not(:disabled) {
        background-color: var(--primary-color);
        color: #fff;
        border: none;
    }

    .spinner {
        position: absolute;
        width: 25px;
        margin-left: 5px;
    }

    .btn-submit.is-loading {
        padding-right: 35px;
    }
}

// No login
.no-login {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 50%;

    .btn-login {
        font-size: 1.5rem;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: var(--primary-color);
        color: #fff;
        font-weight: 600;
        cursor: pointer;
    }

    .btn-login:hover {
        opacity: 0.8;
    }
}

// Responsive

@media screen and (max-width: 767px) {
    .card {
        width: 90%;
        min-height: 60%;
        padding: 15px;

        .input-container {
            margin: 5px 0 8px 0;
        }

        .post-title {
            padding: 5px 0;
        }

        .upload-area {
            padding: 10px;
        }
    }

    .card:has(.no-login) {
        height: 50%;
    }

    .buttons {
        flex: 1;

        button {
            padding: 4px 8px;
        }
    }
}
